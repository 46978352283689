import React from "react"
import Layout from "../../Layout"
import Seo from "../../components/seo"
import Breadcrumbs from "../../components/Breadcrumbs"
import ComingSoon from "../../components/ComingSoon"

const AgriculturalConsultancyPage = () => {
  return (
    <Layout>
      <Seo title="Agricultural Consultancy" />
      <Breadcrumbs title={"Agricultural Consultancy"} />
      <ComingSoon />
    </Layout>
  )
}

export default AgriculturalConsultancyPage
