import React from "react"

const ComingSoon = () => {
  return (
    <div>
      <div className="container my-5 py-5 text-center">
        <h2 className={"text-muted"}>Coming soon</h2>
      </div>
    </div>
  )
}

export default ComingSoon
